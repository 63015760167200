@function calculateRem($size) {
    $remSize: $size / 16px;
    @return #{$remSize}rem;
}


@mixin fontSize($size, $unit:"pt") {
    @if $unit == pt {
        $size: $size * 1.3333 + 0px;
    }
    font-size: calculateRem($size);
}
