.header {
    background: rgba(255, 255, 255, 0.9);
    position: fixed;
    width: 100%;
    z-index: 2;

    .navbar {
        padding: 0;
        position: static;
    }

    .navbar-toggler {
        @include fontSize(22);
        border: none;
    }

    .nav-sections {
        a {
            @include fontSize(13);
            display: block;
            font-weight: bold;
            padding: 15px 15px 0;
            position: relative;
            text-decoration: none;
            text-transform: uppercase;
            transition: 150ms ease-out;

            span {
                position: relative;
                z-index: 2;
            }

            &:before {
                background-color: $color_light_red;
                content: '';
                display: block;
                height: 100%;
                left: 0;
                position: absolute;
                top: 0;
                width: 7px;
                z-index: 1;
                transition: 450ms cubic-bezier(0.175, 0.885, 0.320, 1.275);
            }

            &:hover {
                color: #ffffff;

                &:before {
                    width: 100%;
                }
            }
        }
    }

    .col-search {
        @include make-col-ready();
        @include make-col(2);
        cursor: pointer;

        &:hover {
            path {
                fill: $color_light_red;
            }
        }

        path {
            fill: $color_black;
            transition: fill 150ms ease-out;
        }
    }

    .brand-award {
        display: none;
    }
}

@include media-breakpoint-down(md) {
    .header {
        padding-top: 18px;
        padding-bottom: 18px;
        position: absolute;

        &.sticky {
            position: fixed;

            .brand-award {
                margin-top: 0;
            }

            .img-big {display: none !important;}
            .img-small {display: block !important;}
        }

        .navbar-brand {
            padding: .25rem .75rem;
        }

        .brand-award {
            display: block;
            margin-top: 50px;
        }

        .navbar-brand,
        .navbar-toggler {
            align-self: flex-start !important;
        }

        .navbar-collapse {
            background-color: $color_light_red;
            position: absolute;
            width: 300px;
            top: 0;
            right: -300px;
            height: 100vh;
            padding: 15px 30px;
            text-align: right;
        }

        .nav-sections {
            text-align: left;

            li {
                margin: 5px 0;
                padding: 0 15px;
            }

            .menu-home {
                i {
                    margin-right: 10px;
                    margin-left: -32px;
                    position: relative;
                    top: 3px;
                }
            }

            a {
                @include fontSize(18);
                padding: 10px 0;
            }
        }

        .search-form {
            margin: 50px 0 40px;

            input,
            .input-group-addon {
                background: transparent;
                border-width: 0 0 1px;
                border-color: $color_black;
                padding: 5px;
            }

            input {
                color: $color_black;
            }

            button {
                padding: 0;
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    .header {
        .navbar-brand {
            @include make-col-ready();
            @include make-col(3);

            padding: 8px 0 8px 33px;
            margin-right: 0;
        }

        .col-search {
            text-align: right;
            padding-top: 15px;
        }
    }
}
