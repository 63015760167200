.info-item {
    padding-left: 32px;
    margin-bottom: 32px;
    background-repeat: no-repeat;
    background-position: 0 5px;
}

.info-item--business-hours {
    background-image: url('./../images/ico/time.svg');
}

.info-item--address {
    background-image: url('./../images/ico/mapa.svg');
}

.info-item--phone {
    background-image: url('./../images/ico/phone.svg');
}

.info-item__label {
    @include fontSize(16);
    text-transform: uppercase;
    margin-bottom: 4px;
}

.info-item__value {
    @include fontSize(13);
}

.info-item__action {
    margin-top: 16px;

    .mdc-button {
        padding: 0 15px;
    }

    i {
        margin-right: 15px;
    }
}

@include media-breakpoint-up(lg) {
    .contact-infos {
        padding-top: 200px;
    }
}

@include media-breakpoint-down(md) {
    .contact-infos {
        padding-top: 60px;
    }
}
