// Project colors
$color_black: #3C3C3B;
$color_red: #B00A10;
$color_light_red: #ED697A;
$color_navbar: #1d1d1d;

$color_mdc_hover: #f1efef;

// Header
$header_desktop_height: 35px;
$header_mobile_height: 300px;
$header_mobile_small_height: 35px;

// Menu
$nav_mobile_width: 300px;

// Footer
$footer_height: 235px;
$footer_margin_top: 50px;


// bootstrap override
$grid-gutter-width: 16px;

$navbar-light-color:                rgba($color_navbar,1) !default;
$navbar-light-hover-color:          rgba($color_navbar,.7) !default;
$navbar-light-active-color:         rgba($color_navbar,.9) !default;
$navbar-light-disabled-color:       rgba($color_navbar,.3) !default;
$navbar-light-toggler-border-color: rgba($color_navbar,.1) !default;
