// Search form
/* .search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-secondary;
} */

.form-control::placeholder {
    color: $color_black;
}

.wpcf7 {
    .wpcf7-not-valid-tip {
        position: absolute;
    }

    .wpcf7-response-output {
        border: none;
        padding: 0;
        font-weight: 700;
        margin: 30px 0 0;
    }
}
