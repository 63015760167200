.site-wrapper {
    padding-bottom: $footer_height + $footer_margin_top;
    position: relative;
    overflow: hidden;
    min-height: 100vh;
    width: 100%;
}

.page-header {
    margin-bottom: 30px;

    .projects-manager & {
        align-items: center;
        margin-bottom: 70px;
    }

    h1 {
        @include fontSize(50);
        font-weight: 300;
        text-transform: uppercase;

        .search & {
            @include fontSize(42);
            margin-bottom: 50px;

            span {
                display: block;
                color: $color_light_red;
            }
        }
    }
}

@include media-breakpoint-down(md) {
    .site-wrapper {
        padding-bottom: 0;

        .projects-manager & {
            margin-bottom: 50px;
        }
    }
}
