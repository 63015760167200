.mdc-select {
    width: 100%;
}

.select-manager > select.mdc-select {
    display: none;
}

@media (max-width: 600px) and (pointer: coarse) {
    .select-manager > .mdc-select[role="listbox"] {
        display: none;
    }

    .select-manager > select.mdc-select {
        display: block;
    }
}


.select-manager > .mdc-select[role="listbox"] {
    display: none !important;
}

.select-manager > select.mdc-select {
    display: block !important;
}
