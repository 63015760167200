.col--fixed {
    position: fixed;
    top: $header_desktop_height;
    bottom: 0;
}

@include media-breakpoint-down(md) {
    .container {
        padding-left: 15px;
        padding-right: 15px;
    }

    .row {
        margin-left: -15px;
        margin-right: -15px;
    }

    .site-wrapper .col,
    .site-wrapper [class*='col-'] {
        padding-left: 15px;
        padding-right: 15px;
    }
}
