aside {
    .award-logo {
        margin: 90px 0;
    }

    .sponsor-title {
        @include fontSize(13);
        margin-bottom: 50px;
        text-transform: uppercase;
    }

    .sponsor-img {
        max-width: 160px;
    }

}

.projects-manager .award-logo {
    img {
        max-width: 100%;
    }

    @include media-breakpoint-down(md) {
        img {
            margin-bottom: 15px;
        }
    }
}

@include media-breakpoint-down(md) {
    aside {
        display: none;
    }
}
