.mdc-button {
    height: 32px;
    line-height: 30px;
    transition: all 300ms cubic-bezier(.694, .0482, .335, 1);

    &:hover {
        background-color: $color_mdc_hover;
    }

    &--bigger {
        @include fontSize(12);
    }

    &--smaller {
        padding: 0 25px !important;
    }

    &--unelevated {
        // background-color: $color_red !important;
        @include fontSize(14);

        &:hover {
            color: $color_black;
        }
    }
}


.btn-back {
    @include fontSize(60);
    color: $color_light_red;
    line-height: 1;
    cursor: pointer;

    transition: all 300ms cubic-bezier(.694, .0482, .335, 1);
    transform: translateX(0);
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;

    &:hover {
        color: $color_light_red;
        text-decoration: none;
        transform: translateX(-7px);
        transform: translate3d(-7px, 0, 0);
    }
}
