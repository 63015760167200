.lazy {
    background-color: #fafafa;
    opacity: 0;
}

.lazy:not(.initial) {
    transition: opacity 1s;
}
.lazy.initial,
.lazy.loaded,
.lazy.error {
    opacity: 1;
}
.lazy:not([src]) {
    visibility: hidden;
}
