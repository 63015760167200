footer {
    background-color: $color_black;
    bottom: 0;
    height: $footer_height;
    position: absolute;
    width: 100%;

    .sponsors {
        background-color: #fff;
        padding-bottom: 50px;

        hr {
            background-color: $color_black;
            height: 2px;
            margin-bottom: 30px;
        }

        p {
            @include fontSize(11);
            margin-bottom: 20px;
            text-transform: uppercase;
        }
    }

    .copyright {
        text-align: center;

        p {
            @include fontSize(11);
            color: #ffffff;
            margin: 0;
            padding: 8px 0;
        }
    }
}


@include media-breakpoint-down(md) {
    footer {
        position: static;
        height: auto;

        .sponsors {
            text-align: center;
            padding-bottom: 0;

            hr {
                margin-bottom: 0;
            }
        }

        .sponsors__item {
            margin: 50px 0;
        }

        .copyright p {
            @include fontSize(10);
        }
    }
}
