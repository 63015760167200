.mdc-text-field {
    width: 100%;
}

.mdc-text-field__input {
    @include fontSize(16);
}

.mdc-text-field--textarea .mdc-text-field__label {
    background: transparent;
}

.mdc-text-field__label {
    @include fontSize(16);
    font-weight: 700;
    margin-bottom: 0;
    text-transform: uppercase;
}

.mdc-text-field__label--float-above {
    // @include fontSize(11);
    font-weight: 400;
}

.mdc-button {
    border-width: 1px;
    padding: 0 50px;
}

.mdc-text-field-helper-text {
    color: $color_red;
    font-size: 12px;
    margin-top: 2px;
}
