.entry {
    &:hover {
        .entry__image-wrapper .entry__image {
            transform: scale(1.1) translate3d(0, 0, 0);
        }
    }
}

.entry-infos {
    @include fontSize(14);

    .entry-info__label {
        font-weight: 700;
    }
}

.entry__image-wrapper {
    overflow: hidden;

    .entry__image {
        transition: all 400ms cubic-bezier(.694, .0482, .335, 1);
        transform: scale(1) translate3d(0, 0, 0);
        backface-visibility: hidden;
    }
}

.entries-two-column {
    margin: 0 -56px;
    .entry {
        @include make-col-ready();
        @include make-col(6);
        padding: 0 56px;

        .project-status {
            display: none;
        }

        .entry-title {
            @include fontSize(16);
        }

        .entry-content {
            margin-top: 8px;
        }

        .post-infos {
            @include fontSize(12);
        }
    }

    .related-projects &,
    .home & {
        .entry-info--leader,
        .entry-info--school,
        .entry-info--date {
            display: none;
        }
    }
}

.article-featured {
    margin-bottom: 55px;

    .project-status {
        background-color: $color_light_red;
        display: inline-block;
        @include fontSize(24);
        color: #ffffff;
        padding: 4px 15px;
        margin-bottom: 8px;
        text-transform: uppercase;
        font-weight: 700;
    }

    .entry-title {
        font-weight: 700;
        @include fontSize(26);
        margin: 15px 0;
    }
}

.entry-single {
    .entry-title {
        @include fontSize(30);
        text-transform: uppercase;
    }

    .entry-content img {
        cursor: zoom-in;
    }

    .entry-content-images img {
        margin-bottom: 15px;
    }

    .entry-infos {
        margin: 10px 0 50px;
    }
}

.entry-juri-opinion {
    margin-top: 60px;
}

.entry-juri-opinion__title {
    @include fontSize(25);
    font-weight: 700;
    text-transform: uppercase;
}

.project__pdf-links {
    margin-top: 60px;

    .project__pdf-link-wrapper {
        margin-bottom: 10px;
    }
}

.entry-author {
    @include clearfix;
    margin-top: 60px;
    height: 105px;

    .entry-author__picture {
        float: left;
        max-width: 105px;
        border-radius: 50%;
        margin-right: 15px;
    }

    .entry-author__description {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        line-height: 1.2;
    }
}

.entry-taxonomies {
    border: 3px solid $color_black;
    border-width: 3px 0;
    margin-top: 40px;
    padding: 10px 20px;
    @include fontSize(16);
    font-weight: 300;
    position: relative;

    .entry-taxonomies__item {
        margin: 5px 0;
    }

    strong {
        font-weight: 700;
    }
}

.entry-share {
    position: absolute;
    top: 15px;
    right: 15px;
    overflow: hidden;

    &.is-open .entry-share__canvas {
        transform: translateX(0);
        transform: translate3d(0, 0, 0);
    }

    .entry-share__canvas {
        transition: all 250ms cubic-bezier(0.215, 0.610, 0.355, 1.000);
        transform: translateX(calc(100% - 30px));
        transform: translate3d(calc(100% - 30px), 0, 0);
        backface-visibility: hidden;
    }

    .entry-share__button,
    .entry-share__networks {
        float: left;
    }

    .btn-share-open {
        cursor: pointer;
        padding: 0;
        margin-right: 10px;
    }

    .entry-share__networks {
        .at-label {
            display: none;
        }
    }

    @include media-breakpoint-down(md) {
        position: static;
        margin: 10px 0;

        .entry-share__canvas {
            @include clearfix;
            transform: none;
        }

        &.is-open .entry-share__canvas {
            transform: none;
        }
    }
}

.entry-comments {
    border-bottom: 3px solid $color_black;
    padding-bottom: 15px;
    margin-top: 30px;

    .entry-comments__title {
        @include fontSize(25);
        text-transform: uppercase;
        margin-bottom: 18px;
    }
}

.related-projects {
    margin-top: 65px;

    .related-projects__title {
        background-color: $color_light_red;
        display: inline-block;
        @include fontSize(24);
        color: #ffffff;
        padding: 4px 10px;
        margin-bottom: 40px;
        text-transform: uppercase;
        font-weight: 700;
    }
}


@include media-breakpoint-down(md) {
    .entries-two-column {
        margin: 0 -15px;
        .entry {
            @include make-col(12);
            padding: 0 15px;
        }
    }
}
