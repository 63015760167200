:root {
    --mdc-theme-primary: $color_black;
}

::-moz-selection,
::selection {
    color: #ffffff;
    background: $color_light_red;
}

html {
    font-size: 12px;
    min-height: 100%;
    position: relative;
}

body {
    color: $color_black;
    font-family: 'Open Sans';
    font-weight: 400;
}

h1 {
    font-weight: 300;
}

h1, h2, h3, h4, h5, h5 {
    line-height: 1.2;
}

h1 {font-size: 2.5rem;}
h2 {font-size: 2rem;}
h3 {font-size: 1.75rem;}
h4 {font-size: 1.5rem;}
h5 {font-size: 1.25rem;}
h6 {font-size: 1rem;}

a {
    color: $color_black;
    transition: color 150ms ease-out;

    &:hover {
        color: $color_light_red;
        text-decoration: none;
    }
}

p {
    @include fontSize(18);
    margin-bottom: 2rem;
}

.wrap {
    padding-top: $header_desktop_height;

    .projects-manager & {
        padding-top: 90px;
    }
}

.main {
    padding-top: 150px;

    .projects-manager & {
        padding-top: 0;
    }
}


@include media-breakpoint-down(md) {
    /* html {
        font-size: 8px;
    } */

    .wrap {
        padding-top: 300px;

        .projects-manager & {
            padding-top: 90px;
        }
    }

    .main {
        padding-top: 0;
    }
}



