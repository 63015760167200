.show-nav {
    overflow: hidden;
}


.show-nav .navbar-collapse {
    transform: translateX(-$nav_mobile_width);
    transform: translate3d(-$nav_mobile_width, 0, 0);
}

@include media-breakpoint-down(md) {
    .navbar-collapse {
        transition: all 300ms cubic-bezier(.694, .0482, .335, 1);
        transform: translateX(0);
        transform: translate3d(0, 0, 0);
        backface-visibility: hidden;
    }
}
