.search-wrapper {
    background-color: $color_light_red;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    height: 75px;
    z-index: 99;

    transition: all 200ms cubic-bezier(.694, .0482, .335, 1);
    transform: translateY(-100%);
    transform: translate3d(0, -100%, 0);
    backface-visibility: hidden;

    &.is-open {
        transform: translateY(0);
        transform: translate3d(0, 0, 0);
    }

    .search-form {
        padding-top: 20px;

        input,
        .input-group-addon {
            background: transparent;
            border-width: 0 0 1px;
            border-color: #ffffff;
            padding: 5px;
        }

        input,
        input::placeholder {
            color: #ffffff;
            @include fontSize(18);
        }

        button {
            padding: 0;
        }
    }

    .btn-search {
        path {
            fill: #fff;
        }
    }

    .btn-close-search {
        cursor: pointer;
        position: absolute;
        right: 30px;
        top: 50%;
        transform: translateY(-50%);

        path {
            fill: #fff;
        }
    }
}
