.projects-manager-nav {
    margin-bottom: 45px;

    .nav-tabs {
        border-color: #353535;
    }

    .nav-item {
        margin-left: 20px;

        &:first-child {
            margin-left: 0;
        }
    }

    .nav-link,
    .nav-link.active {
        border-color: #353535;
        border-radius: 0;
        transition: background 200ms ease-out;

        &:hover {
            background-color: $color_mdc_hover;
            border-color: darken(#353535, 50%);
            color: #3c3c3b;
        }
    }

    .nav-link.active {
        border-color: darken(#353535, 50%);
        position: relative;

        &:after {
            background-color: #B00A10;
            bottom: -5px;
            content: '';
            display: block;
            height: 5px;
            left: -1px;
            position: absolute;
            width: calc(100% + 2px);
        }
    }
}

.projects-manager-year-title {
    @include fontSize(40);
    font-weight: 400;
    margin-bottom: 25px;
    text-transform: uppercase;

    .mdc-button {
        height: 28px;
        line-height: 28px;
        margin-left: 20px;
        padding: 0 10px !important;
        vertical-align: baseline;
    }
}

.projects-manager-list {
    .project-actions {
        align-items: center;
        display: flex;
        justify-content: center;
    }

    .btn-project-action {
        background-color: transparent;
        background-position: 0 0;
        background-repeat: no-repeat;
        border: none;
        cursor: pointer;
        display: block;
        margin: 0 5px;

        &--delete {
            background-image: url('./../images/ico/trash.png');
            height: 20px;
            width: 18px;
        }

        &--edit {
            background-image: url('./../images/ico/edit.png');
            height: 18px;
            width: 20px;
        }
    }

    .table {
        thead {
            font-weight: 700;
            text-transform: uppercase;

            td {
                border-top: 0;
            }
        }

        tbody {
            tr:first-child td {
                border-top: 3px solid #312F2F;
                padding-top: 25px;
            }

            tr {
                transition: opacity 200ms ease-out;
            }

            .removing {
                opacity: 0.3;
            }
        }
    }
}

.modal-content {
    width: 100%;

    .mdc-button {
        @include fontSize(12);
        padding: 0 30px;
        outline: none;
    }

    .mdc-button--close {
        @include fontSize(20);
    }
}

.modal-dialog-centered {
    display: flex;
    align-items: center;
    min-height: calc(100% - (#{$modal-dialog-margin} * 2));

    // Ensure `modal-dialog-centered` extends the full height of the view (IE10/11)
    &::before {
        display: block; // IE10
        height: calc(100vh - (#{$modal-dialog-margin} * 2));
        content: "";
    }
}
