.project-form {

    .mdc-text-field:not(.mdc-text-field--textarea) {
        .mdc-text-field__label {
            bottom: 0;
        }

        .mdc-text-field__label--float-above {
            transform: translateY(-80%) scale(.75);
        }
    }

    .mdc-text-field__label {
        color: #3C3C3B;
        @include fontSize(16);
        text-transform: none;
    }

    .mdc-text-field__label--float-above {
        color: #3C3C3B;
        // @include fontSize(12);
        letter-spacing: 0.04em;
        -webkit-font-smoothing: antialiased;
    }

    .mdc-text-field__input {
        color: #3C3C3B;
        @include fontSize(16);
        font-weight: 700;
        letter-spacing: 0.04em;
        -webkit-font-smoothing: antialiased;
        padding-bottom: 0;
    }

    .mdc-text-field {
        margin-top: 5px !important;
        margin-bottom: 0;
    }

    .mdc-select {
        color: #3C3C3B;
        @include fontSize(16);
        font-weight: 700;
        letter-spacing: 0.04em;
        -webkit-font-smoothing: antialiased;
        max-width: 100%;
    }

    .select-manager {
        margin-top: 20px;
    }

    .form-section-wrapper {
        margin-top: 100px;

        &--no-margin {
            margin-top: 10px;
        }

        &--border-top {
            border-top: 1px solid #505050;
            margin-top: 50px;
            padding-top: 49px;
        }

        .page-id-510 & {
            margin-top: 65px;

            &--no-margin {
                margin-top: 10px;
            }
        }
    }

    .form-section-title {
        @include fontSize(18);
        margin: 0;
        text-transform: uppercase;
    }

    .field-wrapper--description {
        margin-top: 40px;

        label {
            color: #3C3C3B;
            @include fontSize(16);
        }

        textarea {
            padding: 10px;
        }
    }

    .field-wrapper--description-pdf {
        margin-bottom: 25px;
    }

    .field-description {
        margin-bottom: 5px;
    }

    .inputs-wrapper {
        display: flex;
    }

    .input-file-wrapper {
        margin-right: 25px;
        width: 240px;
    }

    .inputfile-hidden {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
    }

    .file-label {
        align-items: center;
        background-color: #ECECEC;
        cursor: pointer;
        display: flex;
        height: 130px;
        justify-content: center;
        padding: 5px;
        text-align: center;
        text-transform: uppercase;
        transition: all 150ms ease-out;
        word-break: break-all;

        &.file-selected {
            background-color: #B2D99F;
        }
    }

    .file-input:focus + label,
    .file-input + label:hover {
        background-color: #C5C3C3;
    }

    .file-error-message {
        color: $color_red;
        @include fontSize(14);
        font-weight: 700;
        opacity: 0;
        transition: opacity 200ms ease-out;

        &--inline {
            margin-left: 15px;
        }

        &--block {
            display: block;
            margin-top: 10px;
            text-align: center;
            width: 100%;
        }
    }

    .file-error {
        .file-error-message {
            opacity: 1;
        }

        .file-label {
            background-color: #E17777;
        }
    }

    .form-section-author-image {
        .input-file-wrapper {
            width: 160px;
        }

        .file-label {
            border-radius: 100%;
            height: 160px;
        }
    }

    .form-section-project-file {
        .input-file-wrapper {
            width: auto;
        }

        .file-filename {
            padding-left: 10px;
        }
    }

    .form-section-actions {
        .mdc-button:first-child {
            margin-right: 15px;
        }
    }

    .page-registration-login {
        .form-section-actions {
            margin-top: 50px;
        }
    }

    .form-messages {
        color: $color_red;
        margin: 40px 0;
        padding: 25px 0;

        p {
            margin: 0;
        }

        p + p {
            margin-top: 10px;
        }

        .form-message {
            &--success {
                color: #2fa215;
            }
        }
    }
}
