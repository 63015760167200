.page-id-19 {
    .filters {
        margin-bottom: 32px;
    }

    .filter {
        @include make-col-ready();
    }

    .filter--topic {
        @include make-col(3.7);
    }

    .filter--year {
        @include make-col(2.3);
    }

    .filter--status {
        @include make-col(2.8);
    }

    .filter--school {
        @include make-col(3.2);
    }

    @include media-breakpoint-down(md) {
        .filter {
            @include make-col(12);
            padding-left: 15px;
            padding-right: 15px;
            margin-bottom: 10px;
        }
    }

}

.js-ajax-empty-response {
    display: none;
}
