.search {
    .main {
        .search-form {
            max-width: 300px;
            padding-top: 40px;

            input,
            .input-group-addon {
                background: transparent;
                border-width: 0 0 1px;
                border-color: $color_black;
                padding: 5px;
                border-radius: 0;
            }

            input,
            input::placeholder {
                color: $color_black;
                // @include fontSize(18);
            }

            button {
                padding: 0;
            }
        }

        .btn-search {
            path {
                fill: $color_black;
            }
        }

        .alert-text {
            @include fontSize(18);
        }
    }
}
